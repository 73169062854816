@import '@material/button/mixins';
@import './colors';

.mdc-button {
  @include mdc-button-shape-radius(8px);
}

.mdc-button.grey-outline {
  @include mdc-button-filled-accessible($white);
  @include mdc-button-outline-color($grey);
}

.mdc-button.secondary-color {
  @include mdc-button-container-fill-color($secondary);
}
