@import './colors';

.react-autosuggest__container {
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
}
.react-autosuggest__input {
  width: calc(100% - 28px);
  height: 30px;
  padding: 12px 14px;
  font-weight: 300;
  font-size: 1rem;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
  border: 2px solid $primary;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestions-list .react-autosuggest__suggestion:hover {
  background: $primary;
  color: $white;
}

.react-autosuggest__suggestions-list
  .react-autosuggest__suggestion:hover
  .highlight {
  color: $black;
}

.react-autosuggest__suggestion--highlighted {
  background: $primary;
  color: $white;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
  background-color: #0c7eaf;
  color: #fff;
}

.name {
  line-height: 21px;
}

.highlight {
  color: $red;
  font-weight: 600;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}
