@import './colors';

$mdc-theme-primary: $primary;
$mdc-theme-secondary: $secondary;
$mdc-theme-on-primary: $white;
$mdc-theme-on-secondary: $white;

@import '@material/react-layout-grid/index.scss';
@import '@material/react-material-icon/index.scss';
@import '@material/react-checkbox/index.scss';
@import '@material/react-switch/index.scss';
@import '@material/react-button/index.scss';
@import '@material/react-tab-bar/index.scss';
@import '@material/react-tab-scroller/index.scss';
@import '@material/react-tab/index.scss';
@import '@material/react-tab-indicator/index.scss';
@import '@material/react-radio/index.scss';
@import './react-switch-override';
@import './react-button-overrides';
@import './~slick-carousel/slick/slick.css';
@import './~slick-carousel/slick/slick-theme.css';
@import './node_modules/emoji-picker-react/dist/universal/style.scss';
@import './update-snackbar.scss';
@import './AutoSuggest.scss';

.mdc-text-field--outlined {
  width: 100%;
}

a {
  text-decoration: none;
}

.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}
.MuiStepIcon-root .MuiStepIcon-text {
  fill: $white;
}
.MuiMobileStepper-dots .MuiMobileStepper-dotActive {
  background-color: $primary !important;
}

#distance {
  color: $white;
  background-color: $primary;
  border-radius: 8px;
  line-height: 24px;
  font-size: 16px;
  padding: 8px;
  width: max-content;
}

#trackerEta {
  color: $greyDark;
  background-color: $white;
  border-radius: 8px;
  line-height: 24px;
  font-size: 16px;
  padding: 8px;
  width: max-content;
  display: flex;
  align-items: center;
}

#trackerText {
  padding-left: 8px;
}

.MuiDialog-paper.MuiPaper-rounded {
  border-radius: 8px !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
  color: $red;
}

.mdc-text-field--invalid .mdc-text-field__input {
  color: $red;
  caret-color: $red;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: $red;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $red;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
  border-color: $red;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__path {
  stroke: $red;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
  stroke: $red;
}
