#sw-update-snackbar {
  display: none;
  position: absolute;
  width: 100%;
}
#sw-update-snackbar.show {
  display: inherit;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
  z-index: 9999;
}