$primary: #00b4ad;
$primaryLight: #1EE6D9;
$secondary: #5e35b1;
$secondaryLight: #9A69FF;
$black: #000000;
$white: #ffffff;
$grey: #cbcbcb;
$greyDark: #666666;
$greyLight: #f2f2f2;
$blue: #127efe;
$red: #d32f2f;
$redLight: #ff6b6b;
$yellow: #ffa000;
$green: #00b45a;
$greenLight: #72d9a6;
